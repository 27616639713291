<template>
  <section class="team container pb-8">
    <div class="row">
      <div v-for="item in Teachers" :key="item.teacherId" class="col-6 col-md-4 col-lg-3 mb-3">
        <router-link class="cs-card mb-6" :to="`/teachers/${item.teacherId}`" data-style="5">
          <div class="card-img bg-outer">
            <div
              class="bg-image"
              :style="{
                backgroundImage: 'url(' + item.teacherImagePathl + ')',
              }"
            ></div>
          </div>
          <div class="card-body p-3 text-center">
            <span class="t-name">{{ item.teacherName }}</span>
            <p class="my-2">{{ item.teacherContent }}</p>
          </div>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { GetTeachers } from '@/api/TeachersApi';

export default {
  data() {
    // 建立你的初始化 model data
    return {
      Teachers: [],
    };
  },
  created() {
    this.GetTeachersList();
  },
  mounted() {},
  methods: {
    GetTeachersList() {
      GetTeachers().then((response) => {
        if (response.data.statusCode === 0) {
          this.Teachers = response.data.data;
        }
      });
    },
  },
};
</script>

<style></style>
